<template>
  <c-box
    w="100%"
    mx="auto"
    :min-h="['calc(100vh - 62px)', 'auto']"
    :bg="['#F2F2F2', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['1rem', '1.5rem']"
    :min-height="['unset', '74vh']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="breadcrumb"
      />
    </Portal>
    <c-flex
      flex-direction="column"
      justify-content="space-between"
      flex-grow="1"
      :width="user.role === 'nutritionist' ? '100%': ['100%', '540px']"
      mx="auto"
      :bg="['#FFF', 'transparent']"
      border-radius="16px"
      :p="['1rem', '0']"
    >
      <c-text
        v-if="user.role !== 'nutritionist'"
        :d="['none', 'block']"
        font-family="Roboto"
        font-size="28px"
        color="#111"
        font-weight="700"
        mb="8px"
      >
        Data {{ user.role === 'client' ? 'Klien' : user.role === 'nutritionist' ? 'Ahli Gizi' : 'Admin' }}
      </c-text>

      <c-flex
        v-if="user.role !== 'nutritionist'"
        flex-direction="column"
        :ml="!isClient && !isNutri ? [null, '164px'] : [null, '60px']"
      >
        <c-image
          :src="getPhotoUser(tempPhotoUrl ? tempPhotoUrl : profile.photoUrl)"
          :alt="profile.firstName"
          :w="['150px', '200px']"
          :h="['150px', '200px']"
          mx="auto"
          border-radius="50%"
        />
        <input
          ref="inputAvatar"
          style="display: none"
          type="file"
          accept="image/png, image/jpg, image/jpeg"
          @change="onFileChange"
        >
        <c-button
          v-if="isNutri || isEditable"
          variant="ghost"
          variant-color="blue"
          border-radius="25px"
          flex-direction="row"
          align-items="center"
          h="42px"
          mx="auto"
          mt="8px"
          :is-loading="isUploading"
          loading-text="Uploading"
          font-family="Roboto"
          :font-size="['14px', '16px']"
          @click="
            $refs.inputAvatar.value = '';
            $refs.inputAvatar.click();
          "
        >
          Ganti foto
          <c-image
            :src="require('@/assets/icons/icon-edit-blue.svg')"
            :width="['15px', '22px']"
            :height="['15px', '22px']"
            ml="8px"
          />
        </c-button>
      </c-flex>

      <ProfileFormDetail
        v-if="isSuperAdmin || isAdmin"
        v-model="profile"
        @submit="onSubmit"
      />
      <ProfileNutriFormDetail
        v-if="isNutri"
        v-model="profile"
        @submit="onOpenConfirm"
      />
      <ProfileClientFormDetail
        v-if="isClient"
        v-model="profile"
        :countries="countries"
        :states="states"
        :cities="cities"
        :is-editable="isEditable"
        :errors="errors"
        :photo-changing="!!tempPhotoUrl"
        @submit="onOpenConfirm"
        @edit="onEdit"
        @cancel="onCancel"
      />
      <ModalCropper
        :is-open="isCropper"
        :image="cropper.imageUrl"
        :is-loading="isUploading"
        :file-name="cropper.file ? cropper.file.name : 'file.jpg'"
        @close="onCloseCropper"
        @submit="onCropAvatar"
      />
      <ModalConfirm
        title="Apa kamu yakin ingin mengubah data?"
        :is-open="isConfirm"
        :is-loading="isSubmitting"
        @close="onCloseConfirm"
        @submit="onSubmit"
      />
      <ModalSuccess
        title="Data berhasil diubah!"
        note=""
        :image="require('@/assets/paid.png')"
        :is-open="isSucceeded"
        button-text="Oke"
        @close="handleCloseSuccessModal"
      />
      <ModalSuccess
        title="Terdapat data yang salah di pembaharuanmu"
        note=""
        :image="require('@/assets/images/illustration-6-raw.png')"
        :is-open="isError"
        button-text="Oke"
        @close="handleCloseErrorModal"
      />
    </c-flex>
  </c-box>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import ProfileFormDetail from './form.vue'
import ProfileNutriFormDetail from '@/components/views/profile/detail/form-nutri.vue'
import ProfileClientFormDetail from './form-client.vue'
import generalMixin from '@/utils/general-mixins'
import ModalCropper from '@/components/modals/modal-cropper'
import ModalConfirm from '@/components/widgets/modal-confirm'
import ModalSuccess from '@/components/widgets/modal-success'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'

export default {
  name: 'ProfileDetailPage',
  components: {
    ProfileFormDetail,
    ProfileNutriFormDetail,
    ProfileClientFormDetail,
    ModalCropper,
    ModalConfirm,
    ModalSuccess,
    BreadcrumbPath,
  },
  mixins: [generalMixin],
  data() {
    return {
      profile: {
        address: null,
        birthDate: null,
        birthPlace: null,
        city: null,
        country: null,
        email: null,
        firstName: null,
        gender: null,
        id: null,
        lastName: null,
        phone: null,
        photoUrl: null,
        province: null,
        zipCode: null,
      },
      errors: {},
      isUploading: false,
      cropper: {
        file: null,
        imageUrl: null,
        tempPhoto: null,
      },
      isCropper: false,
      countries: [],
      states: [],
      cities: [],
      isSubmitting: false,
      isEditable: false,
      isConfirm: false,
      isSucceeded: false,
      isError: false,
    }
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
    }),
    ...mapGetters({
      dataProfile: 'profile/dataProfile',
      axios: 'axios',
    }),
    isSuperAdmin() {
      return this.user.role === 'super_admin'
    },
    isAdmin() {
      return this.user.role === 'admin'
    },
    isClient() {
      return this.user.role === 'client'
    },
    isNutri() {
      return this.user.role === 'nutritionist'
    },
    countryId() {
      return this.countries?.find((it) => it.name === this.profile.country)?.id
    },
    tempPhotoUrl() {
      return this.cropper.tempPhoto ? URL.createObjectURL(this.cropper.tempPhoto) : ''
    },
    breadcrumb() {
      if (this.user.role === 'client') {
        return [
          {
            label: 'Dashboard',
            href: '/',
          },
          {
            label: 'Akun',
            href: '/profile',
          },
          {
            label: 'Akun',
            href: '/profile/account',
          },
          {
            label: 'Ubah Data',
            href: '/profile/detail',
            isCurrent: true,
          },
        ]
      } else if (this.user.role === 'nutritionist') {
        return [
          {
            label: 'Beranda',
            href: '/management-client',
          },
          {
            label: 'Akun',
            href: '/profile/account',
          },
          {
            label: 'Detail Ahli Gizi',
            href: '/profile/detail',
            isCurrent: true,
          },
        ]
      } else {
        return [
          {
            label: 'Beranda',
            href: '/admin',
          },
          {
            label: 'Akun',
            href: '/profile/account',
          },
          {
            label: 'Ubah Data Admin',
            href: '/profile/detail',
            isCurrent: true,
          },
        ]
      }
    },
    // stateId() {
    //   return this.states?.find((it) => it.name === this.profile.province)?.id
    // },
    // cityId() {
    //   return this.cities?.find((it) => it.name === this.profile.city)?.id
    // },
  },
  watch: {
    // countryId(id) {
    //   this.getStates(id)
    // },
    // stateId(id) {
    //   this.getCities(id)
    // },
    dataProfile: {
      immediate: true,
      handler(val) {
        if (!val || this.isUploading) return
        if (this.isClient) {
          this.profile.address = val.address
          this.profile.birthDate = val.birthDate
          this.profile.birthPlace = val.birthPlace
          this.profile.city = val.city
          this.profile.country = val.country
          this.profile.email = val.email
          this.profile.firstName = val.firstName
          this.profile.gender = val.gender
          this.profile.id = val.id
          this.profile.lastName = val.lastName
          this.profile.phone = val.phone
          this.profile.photoUrl = val.photoUrl
          this.profile.province = val.province
          this.profile.zipCode = val.zipCode
        } else if (this.isNutri) {
          // const specialization = this.isArray(val?.specialization) ? val.specialization.join(', ') : val?.specialization
          // const problemHandled = this.isArray(val?.problemHandled) ? val.problemHandled.join(', ') : val?.problemHandled
          const languages = this.isArray(val?.languages) ? val.languages : val?.languages.split(',').map((it) => { return it.trim() }).filter((it) => it)
          const clientAgeHandled = this.isArray(val?.clientAgeHandled) ? val.clientAgeHandled : val?.clientAgeHandled.split(',').map((it) => { return it.trim() }).filter((it) => it)
          const workExperiences = val?.workExperiences.reverse().map((it) => ({
            position: it?.position,
            companyName: it?.companyName,
            startYear: it?.startYear,
            endYear: it?.endYear == 'Sekarang' ? null : it?.endYear,
            isCurrentlyWork: it?.endYear == 'Sekarang' ? true : false,
          }))

          this.profile = {
            id: val.id,
            photoUrl: val.photoUrl,
            email: val.email,
            str: val.str,
            firstName: val.firstName,
            lastName: val.lastName,
            education: val.education,
            specialization: val.specialization,
            problemHandled: val.problemHandled,
            languages,
            clientAgeHandled,
            workExperiences,
          }
        } else {
          this.profile = val
        }
      },
    },
  },
  async mounted() {
    this.getCountries()

    if (this.isSuperAdmin) {
      await this.getProfileSuperadmin()
    }
    if (this.isAdmin) {
      await this.getProfileAdmin()
    }
    if (this.isNutri) {
      await this.n_getProfile()
    }
    if (this.isClient) {
      await this.getProfile()
    }
    await Promise.all([
      // this.getStates(),
      // this.getCities(),
    ])
  },
  methods: {
    ...mapActions({
      // superadmin
      getProfileSuperadmin: 'profile/getProfileSuperadmin',
      updateProfileSuperadmin: 'profile/updateProfileSuperadmin',

      // admin
      getProfileAdmin: 'profile/getProfileAdmin',
      updateProfileAdmin: 'profile/updateProfileAdmin',

      // nutritionist
      n_getProfile: 'profile/getProfile',
      n_updateProfile: 'profile/updateProfile',

      // client
      getProfile: 'profile/getProfileClient',
      updateProfile: 'profile/updateProfileClient',
      uploadAvatar: 'profile/uploadAvatar',
    }),
    onOpenCropper() {
      this.isCropper = true
    },
    onCloseCropper() {
      this.cropper.imageUrl = null
      this.cropper.file = null
      this.isCropper = false
    },
    onFileChange(input) {
      if (input.target.files && input.target.files[0]) {
        let file = input.target.files[0]
        if (file.size > 2097152) {
          return this.$toast({
            title: 'Failed',
            description: `This file can't be uploaded, because it (${this.formatBytes(
              file.size,
            )}) exceeds the maximum file size (2 MB).`,
            status: 'error',
            duration: 10000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        }

        this.cropper.file = input.target.files[0]
        this.cropper.imageUrl = URL.createObjectURL(input.target.files[0])
        if (this.cropper.imageUrl) {
          this.isCropper = true
        }
      }
    },
    onCropAvatar(file) {
      this.cropper.tempPhoto = file
      this.onCloseCropper()
    },
    onUpload(file) {
      let formData = new FormData()
      formData.append('file', file)

      return this.axios
        .post('/v1/users/upload', formData)
    },
    onEdit() {
      this.isEditable = true
    },
    onCancel() {
      // avatar
      this.cropper.file = null
      this.cropper.imageUrl = null
      this.cropper.tempPhoto = null

      // form
      this.profile.address = this.dataProfile.address
      this.profile.birthDate = this.dataProfile.birthDate
      this.profile.birthPlace = this.dataProfile.birthPlace
      this.profile.city = this.dataProfile.city
      this.profile.country = this.dataProfile.country
      this.profile.email = this.dataProfile.email
      this.profile.firstName = this.dataProfile.firstName
      this.profile.gender = this.dataProfile.gender
      this.profile.id = this.dataProfile.id
      this.profile.lastName = this.dataProfile.lastName
      this.profile.phone = this.dataProfile.phone
      this.profile.photoUrl = this.dataProfile.photoUrl
      this.profile.province = this.dataProfile.province
      this.profile.zipCode = this.dataProfile.zipCode
      this.errors = {}
      this.isEditable = false
    },
    onOpenConfirm() {
      this.isConfirm = true
    },
    onCloseConfirm() {
      this.isConfirm = false
      this.isSubmitting = false
      this.isUploading = false
    },
    handleCloseSuccessModal() {
      this.isSucceeded = false
    },
    handleCloseErrorModal() {
      this.isError = false
    },
    async onSubmit() {
      this.errors = {}
      this.isSubmitting = true
      if (this.isSuperAdmin) {
        this.updateProfileSuperadmin({ profile: this.profile })
          .then(() => {
            this.$toast({
              title: 'Success',
              description: 'Your profile has been updated.',
              status: 'success',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
          .catch((err) => {
            this.$toast({
              title: 'Failed',
              description: err.data.message ?? 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
      }
      if (this.isAdmin) {
        this.updateProfileAdmin({ profile: this.profile })
          .then(() => {
            this.$toast({
              title: 'Success',
              description: 'Your profile has been updated.',
              status: 'success',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
          .catch((err) => {
            this.$toast({
              title: 'Failed',
              description: err.data.message ?? 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
      }
      if (this.isNutri) {
        if (this.cropper.tempPhoto) {
          try {
            const { data } = await this.onUpload(this.cropper.tempPhoto)
            this.profile.photoUrl = data?.data?.url
          } catch (err) {
            this.$toast({
              title: 'Failed',
              description: 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
            return
          }
        }
        const payload = {
          ...this.profile,
          // specialization: this.profile.specialization ? this.profile.specialization.split(',').map((it) => { return it.trim() }).filter((it) => it) : null,
          problemHandled: this.profile.problemHandled ? this.profile.problemHandled.filter((it) => it && it !== '') : null,
          languages: this.profile.languages.filter((it) => it && it !== '').join(', '),
          clientAgeHandled: this.profile.clientAgeHandled.filter((it) => it !== '').join(', '),
          education: this.profile.education.map((it) => {
            return {
              ...it,
              otherUniversity: it.university === '0' ? it.otherUniversity : null,
            }
          }),
          workExperiences: this.profile.workExperiences.map((it) => {
            return {
              ...it,
              endYear: it?.isCurrentlyWork ? 'Sekarang' : it?.endYear,
            }
          }),
        }
        this.n_updateProfile({ profile: payload })
          .then(() => {
            this.$toast({
              title: 'Success',
              description: 'Your profile has been updated.',
              status: 'success',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
          .catch((err) => {
            this.$toast({
              title: 'Failed',
              description: err.data.message ?? 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
          .finally(() => {
            if (this.cropper.tempPhoto) {
              this.cropper.tempPhoto = null
            }
            this.onCloseConfirm()
          })
      }
      if (this.isClient) {
        let payload = {
          birthDate: this.profile.birthDate,
          country: this.profile.country,
          email: this.profile.email,
          firstName: this.profile.firstName,
          gender: this.profile.gender,
          id: this.profile.id,
          lastName: this.profile.lastName,
          phone: this.profile.phone,
          photoUrl: this.profile.photoUrl,
        }
        if (this.cropper.tempPhoto) {
          this.isUploading = true
          try {
            const { data } = await this.onUpload(this.cropper.tempPhoto)
            payload.photoUrl = data?.data?.url
          } catch (err) {
            this.isError = true
            this.$toast({
              title: 'Failed',
              description: 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
            return
          }
          this.isUploading = false
        }
        this.updateProfile({ profile: payload })
          .then(async(res) => {
            if (res.status) {
              this.isEditable = false
              this.isSucceeded = true
            } else {
              this.errors = res.message
              this.isError = true
            }
          })
          .catch((err) => {
            this.errors = err.data.message
            this.isError = true
          })
          .finally(() => {
            this.onCloseConfirm()
          })
      }
    },
    async getCountries() {
      let { data } = await this.axios
        .get('/v1/general/countries')
        .then((it) => it.data)

      if (!data) return
      this.countries = data.map((data) => {
        return {
          ...data,
          value: data.name,
          label: data.name,
        }
      })
    },
    async getStates(countryId) {
      let { data } = await this.axios
        .get(`/v1/general/states?countryId=${countryId}`)
        .then((it) => it.data)

      this.states = data.map((data) => {
        return {
          ...data,
          value: data.name,
          label: data.name,
        }
      })
    },
    async getCities(stateId) {
      let { data } = await this.axios
        .get(`/v1/general/cities?stateId=${stateId}`)
        .then((it) => it.data)

      if (!data) return
      this.cities = data.map((data) => {
        return {
          ...data,
          value: data.name,
          label: data.name,
        }
      })
    },
  },
}
</script>
